import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@components/layout/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
export default class BookRoom extends React.Component {
  constructor(props) {
    super(props);
    ['getPersonsHtml', 'calculateTotal', 'onInputNotes'].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.state = {
      rooms: 1,
      startDate: null,
      endDate: null,
      reservationMade: Object.keys(this.props.user.accomodation).length > 0,
    };

    this.notes = '';
  }

  getPersonsHtml(capacity) {
    const html = [];
    for (let i = 1; i <= capacity; i++) {
      html.push(
        <i key={`person-${i}`} className="icon-user1" aria-hidden="true" />
      );
    }
    return html;
  }

  getOptions() {
    const options = {};
    for (let i = 1; i <= this.props.room.availableRooms; i++) {
      options[i] = i;
    }
    return options;
  }

  calculateTotal() {
    const { startDate, endDate } = this.props.dates;
    const { rooms } = this.state;
    const { room } = this.props;
    const days = moment(endDate, 'DD-MM-YYYY').diff(
      moment(startDate, 'DD-MM-YYYY'),
      'days'
    );
    const total = days * room.sellingPrice * rooms;
    return Number(total.toFixed(2));
  }

  onInputNotes(e) {
    this.notes = e.target.value;
  }

  render() {
    const { room, currency, dates, available, showAvailable } = this.props;
    const hasReservation = !!this.props.user.accomodation?.id;
    const hasSubscription = !!this.props.user.subscription;
    const ableToBook =
      (hasSubscription && this.props.user.subscriptionDueAmount <= 0) ||
      this.props.user.info.sponsored == 1;
    return (
      <div className="book-room">
        <div className="book-room-slider">
          {room.hotelRoomImages?.length > 0 ? (
            <Slider
              showThumbnails={false}
              type="room"
              images={room.hotelRoomImages}
            />
          ) : (
            <div className="image-placeholder">
              <ImageOutlinedIcon
                className="icon-presentation"
                aria-hidden="true"
              />
            </div>
          )}
        </div>

        <div className="book-room-info-wrapper">
          <div className="room-name">{this.props.room.name}</div>
          <div
            className={`show-availability ${
              !showAvailable ? 'invisibile' : ''
            }`}>
            {showAvailable && dates.startDate && dates.endDate ? (
              <p>{`${moment(dates.startDate, 'DD-MM-YYYY').format(
                'DD/MM'
              )} - ${moment(dates.endDate, 'DD-MM-YYYY').format(
                'DD/MM'
              )} (${moment(dates.endDate, 'DD-MM-YYYY').diff(
                moment(dates.startDate, 'DD-MM-YYYY'),
                'days'
              )} ${window.lang[this.props.language]['nights']})`}</p>
            ) : (
              `${window.lang[this.props.language]['selectDatesButton']}`
            )}
          </div>
          <div className="room-info">
            <div className="room-info-field">
              <div className="room-info-title">
                {window.lang[this.props.language]['numberOfGuests']}
              </div>
              <div className="room-info-item">{room.capacity}</div>
            </div>
            <div className="room-info-field">
              <div className="room-info-title">
                {window.lang[this.props.language]['pricePerNight']}
              </div>
              <div className="room-info-item">
                {room.sellingPrice}
                {this.props.currency}
              </div>
            </div>
            <div className="room-info-field">
              <div className="room-info-title">
                {window.lang[this.props.language]['facilities']}
              </div>

              <Tooltip title={room.facilities}>
                <div className="room-info-item">
                  {room.facilities.slice(0, 25) + '...'}
                </div>
              </Tooltip>
            </div>
            <div className="room-info-field">
              <div className="room-info-title">
                {window.lang[this.props.language]['description']}
              </div>
              <Tooltip title={room.description}>
                <div className="room-info-item description">
                  {room.description}
                </div>
              </Tooltip>
            </div>
          </div>

          <div className="book-room-notes">
            <div className="notes-title">
              {window.lang[this.props.language]['addNote']}
            </div>
            <textarea
              className="notes-text-area"
              value={this.props.user.accomodation.notes}
              placeholder={
                window.lang[this.props.language]['addNotePlaceholder']
              }
              disabled={hasReservation}
              onInput={(e) => this.onInputNotes(e)}
            />
          </div>

          {/* //* IS ABLE TO MAKE A BOOKING AND HAS NO RESERVATION */}
          {ableToBook && !hasReservation && (
            <div className="create-reservation">
              {this.props.dates?.startDate && this.props.dates?.endDate ? (
                available ? (
                  <button
                    className="btn btn-primary book-now-button"
                    onClick={() => {
                      this.props.createHotelReservation(
                        room.eventHotelId,
                        room.orgHotelRoomId,
                        dates.startDate,
                        dates.endDate,
                        this.notes,
                        this.state.rooms
                      );
                    }}>
                    {`${
                      this.calculateTotal()
                        ? `${window.lang[this.props.language]['bookNowFor']} ${
                            currency + this.calculateTotal()
                          }`
                        : window.lang[this.props.language]['bookNow']
                    }`}
                  </button>
                ) : (
                  <React.Fragment>
                    <Tooltip
                      title={
                        window.lang[this.props.language]['outOfDateRange']
                      }>
                      <div className="not-available">
                        {window.lang[this.props.language]['notAvailable']}
                      </div>
                    </Tooltip>
                  </React.Fragment>
                )
              ) : (
                <Tooltip
                  title={window.lang[this.props.language]['selectDates']}>
                  <button disabled className="btn btn-primary book-now-button">
                    {this.props.dates.startDate && this.props.dates.endDate
                      ? window.lang[this.props.language]['bookNow']
                      : window.lang[this.props.language]['selectDatesButton']}
                  </button>
                </Tooltip>
              )}
            </div>
          )}

          {/* //* IS ABLE TO MAKE A BOOKING BUT ALREADY HAS A RESERVATION  */}
          {/* //* IS NOT ABLE TO MAKE A BOOKING BUT ALREADY HAS A RESERVATION */}
          {hasReservation &&
            (this.props.user.accomodation.dueAmount <= 0 ? (
              <div className="reservation-paid">
                {window.lang[this.props.language]['completedReservation']}
              </div>
            ) : (
              <div className="reservation-not-paid">
                <button
                  className="btn btn-primary go-to-payments-button"
                  onClick={() => this.props.changeSection('billing')}>
                  {window.lang[this.props.language]['goToPayments']}
                </button>

                <button
                  className="btn cancel-reservation-button"
                  onClick={() => {
                    this.props.cancelHotelReservation(
                      this.props.user.eventId,
                      this.props.user.accomodation.eventHotelId,
                      this.props.user.accomodation.id
                    );
                  }}>
                  {window.lang[this.props.language]['cancelReservation']}
                </button>
              </div>
            ))}

          {/* //* IS NOT ABLE TO MAKE A BOOKING AND HAS NO RESERVATION */}
          {!ableToBook && !hasReservation && (
            <div className="not-available">
              {window.lang[this.props.language]['notAvailable']}
            </div>
          )}
        </div>
      </div>
    );
  }
}

BookRoom.propTypes = {
  room: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  createHotelReservation: PropTypes.func.isRequired,
  cancelHotelReservation: PropTypes.func.isRequired,
  changeSection: PropTypes.func.isRequired,
  showPayment: PropTypes.func.isRequired,
  getUser: PropTypes.func,
  user: PropTypes.object,
  dates: PropTypes.object,
  counter: PropTypes.object,
  language: PropTypes.string,
  available: PropTypes.bool,
  showAvailable: PropTypes.bool,
  reserved: PropTypes.number,
};
