import React from 'react';
import { ShoppingCartIcon } from '../../../../../../dynamic_assets/shopingCartIcon.svg';
import PropTypes from 'prop-types';
import { CartIcon } from '../../../../../../dynamic_assets/cartIcon.svg';

export default function CartComponent({
  language,
  currency,
  handleProceedToPurchase,
  cart,
  clearCart,
  totalItemsInCart,
  totalAmount,
}) {
  return (
    <div className="mycart-container">
      <div className="cart">
        <div className="cart-header">
          <div className="cart-title">
            <ShoppingCartIcon />
            <h4>
              {window.lang[language]['myCart']}
              {totalItemsInCart > 0 ? ` (${totalItemsInCart})` : ''}
            </h4>
          </div>

          {totalItemsInCart > 0 && (
            <span className="clear-cart" onClick={clearCart}>
              {`${window.lang[language]['emptyCart']}`}
            </span>
          )}
        </div>
        <div className="cart-body">
          {totalItemsInCart === 0 && (
            <div className="empty-cart-icon">
              <CartIcon />
              <p className="primary-message">Your shopping cart is empty!</p>
              <p className="secondary-message">Start adding products</p>
            </div>
          )}
          {cart?.map?.((item, index) => (
            <div className="cart-item" key={`${item.id}-${index}`}>
              <div className="cart-item-details">
                <div>{item.quantity}x</div>
                <div>{` ${item.eventProductName} - ${item.name} `}</div>
              </div>
              <div className="cart-item-price">
                {isFinite(item.sellingPrice)
                  ? item.sellingPrice.toFixed(2)
                  : 'N/A'}{' '}
                {currency}
              </div>
            </div>
          ))}
        </div>
        {cart?.length > 0 && (
          <div className="cart-footer">
            <button
              className="round-button"
              onClick={handleProceedToPurchase(cart)}>
              <div className="button-content">
                <span>{window.lang[language]['proceedToPurchase']}</span>
                {totalAmount} {currency}
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

CartComponent.propTypes = {
  language: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  handleProceedToPurchase: PropTypes.func.isRequired,
  cart: PropTypes.array.isRequired,
  clearCart: PropTypes.func.isRequired,
  totalItemsInCart: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
};
