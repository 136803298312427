import React from 'react';
import PropTypes from 'prop-types';
import Map from '@components/layout/Map';
import BookRoom from './BookRoom/';
import Slider from '@components/layout/Slider';
import { isEmpty } from '@helpers';
import ReactDOMServer from 'react-dom/server';
import { animateScroll as reactScroll } from 'react-scroll';
import moment from 'moment';
import Widget from '../Partials/Widget';
import WarningBar from './WarningBar/WarningBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Loader from '../../../../../layout/Loader';
export default class HotelInner extends React.Component {
  constructor(props) {
    super(props);
    ['getItems', 'getAvailableRoomsInner'].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.state = {
      isLoading: true,
      selectedDates: {
        from: this.props.hotelDates?.from ? this.props.hotelDates.from : null,
        to: this.props.hotelDates?.to ? this.props.hotelDates.to : null,
      },
      guests: this.props.hotelGuests || 1,
      availableRooms: [],
    };
  }

  getItems(id) {
    return [...this.props.hotel.rooms]
      .map((r) => {
        if (id && id !== r.orgHotelRoomId) {
          return null;
        }
        if (r.quantity > 0) {
          return { id: r.id, label: r.name };
        }
        return null;
      })
      .filter((r) => r !== null);
  }

  componentDidMount() {
    reactScroll.scrollToTop();

    if (isEmpty(this.props.hotel)) {
      this.props
        .getHotels()
        .then(() => {
          const meta = this.checkHotelFilters();
          return this.getAvailableRoomsInner(meta);
        })
        .catch((error) => {
          console.error('An error occurred:', error);
        });
    } else {
      const meta = this.checkHotelFilters();
      this.getAvailableRoomsInner(meta);
    }
  }

  checkHotelFilters() {
    const meta = {};

    if (this.state.selectedDates.from && this.state.selectedDates.to) {
      meta.dateRange = JSON.stringify({
        from: this.state.selectedDates.from,
        to: this.state.selectedDates.to,
      });
    }

    if (this.state.guests) meta.guests = this.state.guests;
    return meta;
  }

  getAvailableRoomsInner(meta) {
    this.setState({ isLoading: true });
    this.props
      .getAvailableRooms(this.props.hotel.id, meta)
      .then((response) => {
        const rooms = Array.isArray(response.data) ? response.data : [];
        let from = null,
          to = null;
        if (typeof meta.dateRange === 'string') {
          const { from: parsedFrom, to: parsedTo } = JSON.parse(meta.dateRange);
          from = parsedFrom || null;
          to = parsedTo || null;
        }
        this.setState({
          availableRooms: rooms,
          isLoading: false,
          selectedDates: { from, to },
        });
      })
      .catch(() => {
        this.setState({
          availableRooms: [],
          isLoading: false,
        });
      });
  }

  buildStarsHtml(hotelId, stars, jsx = true) {
    const html = [];
    for (let i = 1; i <= stars; i++) {
      html.push(
        <i
          key={`star-${hotelId}-${i}`}
          className={`icon-fill-1 ${i <= stars ? ' active' : '-o'}`}
          aria-hidden="true"
        />
      );
    }
    return jsx ? html : ReactDOMServer.renderToStaticMarkup(html);
  }

  getUserRoom(tab) {
    const { accomodation } = this.props.user;
    if (!accomodation || accomodation.eventHotelRoomId !== tab) return null;
    return this.props.hotel.rooms.find(
      (r) => r.orgHotelRoomId === accomodation.orgHotelRoomId
    );
  }

  formatDate(dateString) {
    return moment(dateString, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY');
  }

  getContent(tab) {
    let room = this.getUserRoom(tab);
    let startDate, endDate;
    let isRoomAvailable = !!room;
    const reserved = !!room;

    if (room) {
      startDate = this.formatDate(this.props.user.accomodation.startDate.tz);
      endDate = this.formatDate(this.props.user.accomodation.endDate.tz);
    } else {
      room = this.state.availableRooms.find((r) => r.id === tab);
      if (room) {
        isRoomAvailable = true;
        startDate = this.state.selectedDates.from
          ? this.formatDate(this.state.selectedDates.from)
          : null;
        endDate = this.state.selectedDates.to
          ? this.formatDate(this.state.selectedDates.to)
          : null;
      }
    }

    if (!room) return null;

    return (
      <BookRoom
        key={room.id}
        room={room}
        available={isRoomAvailable}
        showAvailable={isRoomAvailable}
        dates={{ startDate, endDate }}
        reserved={reserved}
      />
    );
  }

  scrollToRef(ref) {
    return scrollTo(ref, 1);
  } // General scroll to element function

  render() {
    const { hotel, language } = this.props;
    const primaryColor =
      this.props.event &&
      this.props.event.clientPanelSettings &&
      this.props.event.clientPanelSettings.general.colors.primary;
    const hasSubscription = !!this.props.user.subscription;
    const ableToBook =
      hasSubscription && this.props.user.subscriptionDueAmount <= 0;
    const hasReservation = !!this.props.user.accomodation?.id;
    let message;
    if (!ableToBook && !hasReservation && this.props.user.info.sponsored != 1) {
      message = window.lang[this.props.language]['payYourRegistration'];
    } else if (
      hasReservation &&
      this.props.user.accomodation.dueAmount > 0 &&
      this.props.user.accomodation.eventUserFullName ===
        this.props.user.accomodation.reservedBy
    ) {
      message = `${
        window.lang[this.props.language]['yourReservationWillExpireAt']
      } ${moment(this.props.user.accomodation.expiresAt.tz, false).format(
        'HH:mm:ss'
      )}`;
    }

    if (isEmpty(hotel)) return null;
    return (
      <React.Fragment>
        <div
          className={`accomodation-container-inner ${
            !(!ableToBook && !hasReservation) ? 'warning-bar-adjustment' : ''
          }`}>
          <WarningBar
            message={message}
            button={
              <button
                className="btn btn-secondary go-to-payments-button"
                onClick={() => this.props.changeSection('billing')}>
                {window.lang[this.props.language]['goToPayments']}
              </button>
            }
          />
          <div className="topside-wrapper">
            <div className="topside-inner-wrapper">
              <div
                className="navigate-back-abstracts"
                onClick={() =>
                  this.props.changeSection('hotelManagement', true)
                }>
                <ArrowBackIcon />
                {lang[this.props.language]['backToHotels']}
              </div>
              <Widget
                event={this.props.event}
                language={language}
                guests={this.state.guests}
                roomWidget={true}
                hotel={hotel}
                hotelDates={this.state.selectedDates}
                checkAvailability={this.getAvailableRoomsInner}
              />
              <div className="hotels-list">
                <div className="hotel-name">{hotel.name}</div>
                <div
                  className="kmb-stars"
                  title={`${hotel.stars} star${
                    hotel.stars > 1 ? 's' : ''
                  } hotel`}>
                  {this.buildStarsHtml(hotel.id, hotel.stars)}
                </div>
                <div className="hotel-address">{hotel.address}</div>
                <div className="hotel-visual-info">
                  {hotel.hotelImages?.length > 0 && (
                    <div className="hotel-slider">
                      <Slider
                        showThumbnails={true}
                        show
                        type="hotel"
                        images={hotel.hotelImages}
                      />
                    </div>
                  )}
                  <div
                    className={`hotel-map ${
                      hotel.hotelImages?.length !== 0 ? '' : 'sole-component'
                    }`}>
                    <Map
                      showLocationMarker={true}
                      markers={[
                        {
                          lat: hotel.latitude,
                          lng: hotel.longitude,
                          id: hotel.id,
                          name: hotel.name,
                          height: 160,
                        },
                      ]}
                      zoomLocation={{
                        lat: hotel.latitude,
                        lng: hotel.longitude,
                      }}
                      singleMarker={true}
                      primaryColor={primaryColor}
                    />
                  </div>
                </div>
                {hotel.description && (
                  <div className="box description">
                    <p>{hotel.description}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="hotels-wrapper inner hotel-room-card-wrapper hotel-room-card">
            <div className="room-booking-header">
              <div className="room-types">
                {window.lang[this.props.language]['roomTypes']}
              </div>

              <div className="results-found">
                ({this.state.availableRooms.length}{' '}
                {window.lang[this.props.language]['results']})
              </div>
            </div>
            {!this.state.isLoading ? (
              hasReservation ? (
                [
                  this.getUserRoom(
                    this.props.user.accomodation.eventHotelRoomId
                  ),
                ].map((room) => {
                  return this.getContent(room?.id);
                })
              ) : (
                this.state.availableRooms.map((room) => {
                  return this.getContent(room.id);
                })
              )
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

HotelInner.propTypes = {
  changeSection: PropTypes.func.isRequired,
  hotel: PropTypes.object,
  getHotels: PropTypes.func.isRequired,
  getAvailableRooms: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hotelDates: PropTypes.object,
  hotelGuests: PropTypes.number,
  user: PropTypes.object,
  event: PropTypes.object.isRequired,
};

// c = element to scroll to or top position in pixels
// e = duration of the scroll in ms, time scrolling
// d = (optative) ease function. Default easeOutCuaic
function scrollTo(c, e, d) {
  d || (d = easeOutCuaic);
  let a = document.documentElement;
  let b = 0;
  if (0 === a.scrollTop) {
    b = a.scrollTop;
    ++a.scrollTop;
    a = b + 1 === a.scrollTop-- ? a : document.body;
  }
  b = a.scrollTop;
  0 >= e ||
    ('object' === typeof b && (b = b.offsetTop),
    'object' === typeof c && (c = c.offsetTop),
    (function (a, b, c, f, d, e, h) {
      function g() {
        0 > f || 1 < f || 0 >= d
          ? (a.scrollTop = c)
          : ((a.scrollTop = b - (b - c) * h(f)),
            (f += d * e),
            setTimeout(g, e));
      }
      g();
    })(a, b, c, 0, 1 / e, 20, d));
}
function easeOutCuaic(t) {
  t--;
  return t * t * t + 1;
}
