import React from 'react';
import PropTypes from 'prop-types';
import { bluetreeInternalURls } from '../../helpers';
export default class NavigateModal extends React.Component {
  constructor(props) {
    super(props);
    document.body.style.overflow = 'hidden';
  }

  handleClose(e) {
    e.stopPropagation();
    document.body.style.overflow = 'auto';
    this.props.changeModalStatus();
  }

  render() {
    const { color, linkToNavigateTo } = this.props;

    const navigateIfInternalUrl = (linkToNavigateTo) => {
      if (
        bluetreeInternalURls.some((internalURL) =>
          linkToNavigateTo.includes(internalURL)
        )
      ) {
        window.open(linkToNavigateTo);
        return true;
      }

      return false;
    };

    if (navigateIfInternalUrl(linkToNavigateTo)) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="backdrop" onClick={(e) => this.handleClose(e)} />
        <div className="navigate-modal" id="modal">
          <div className="lectical">
            {window.lang[this.props.language]['navigateOutOfSite']}
          </div>
          <div className="btn-row">
            <div
              className="confirm btn"
              style={{ backgroundColor: color }}
              onClick={(e) => {
                this.handleClose(e);
                window.open(linkToNavigateTo);
              }}>
              {window.lang[this.props.language]['confirm']}
            </div>
            <div
              className="cancel btn"
              onClick={(e) => this.handleClose(e)}
              style={{ color }}>
              {window.lang[this.props.language]['cancel']}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

NavigateModal.propTypes = {
  changeModalStatus: PropTypes.func,
  language: PropTypes.string,
  color: PropTypes.string,
  linkToNavigateTo: PropTypes.string,
};
